import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { DataGrid, deDE, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import "../../../App.css";
import { Button, Icon, IconButton, LinearProgress } from "@mui/material";
import {
  Add,
  Close,
  Delete,
  Done,
  Engineering,
  Person,
  PersonOffOutlined,
  ReplayOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import {
  addEmployee,
  deleteEmployee,
  getEmployees,
  updateEmployee,
} from "../../../Redux/Actions/fireactions";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function CustomToolbar(props, showDelete, setShowDelete, rows, setRows, adding, setAdding) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleAdd = () => {
    setAdding(true);
    const id = "new";
    setRows((oldRows) => [{ id, name: "" }, ...oldRows]);
  };
  return (
    <div className="Table-toolbar">
      <Icon fontSize="large" className="Table-icon">
        <Person fontSize="large" style={{ color: "white" }} />
      </Icon>
      <div
        className="Table-icon-bg"
        style={{
          background: process.env.REACT_APP_PRIMARY_CLR_DARK,
        }}
      ></div>

      <Typography
        component="h1"
        variant="h4"
        color="secondary"
        // noWrap
        // sx={{ flexGrow: 1 }}
        // style={{ textTransform: "capitalize" }}
      >
        {/* Repairs */}
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <GridToolbarQuickFilter
          debounceMs={500}
          variant="outlined"
          placeholder={t("Search Employee")}
        />
        <Button
          sx={{ fontSize: "1rem", marginLeft: "15px" }}
          startIcon={<Add />}
          variant="contained"
          onClick={handleAdd}
          disabled={adding}
        >
          {t("Contractor")}
        </Button>
        <IconButton
          fontSize={"large"}
          sx={{ marginLeft: "10px" }}
          onClick={() => dispatch(getEmployees())}
        >
          <ReplayOutlined fontSize={"large"} color="primary" />
        </IconButton>
        <IconButton
          fontSize={"large"}
          sx={{ marginLeft: "10px" }}
          onClick={() => setShowDelete(!showDelete)}
        >
          <Delete fontSize={"large"} color="primary" />
        </IconButton>
      </div>
    </div>
  );
}

const dateTimeOptions = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZone: "Europe/Berlin",
};

export default function Employees(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.job.employees);
  const employeesLoading = useSelector((state) => state.job.employeesLoading);
  const [showDelete, setShowDelete] = useState(false);

  const [adding, setAdding] = useState(false);

  const handleDeleteEmployee = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("row :>> ", params);
    dispatch(deleteEmployee(params.id));
  };

  const handleAddTechnician = (e, params) => {
    // e.preventDefault();
    // e.stopPropagation();
    // console.log("row :>> ", params.row);
    // const technician = { name: params.row.name };
    // dispatch(addTechnician(technician));
    // setAdding(false);
  };

  const handleCancelAdding = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setRows([...rows.slice(1)]);
    setAdding(false);
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: "Name",
      autofocus: true,
      editable: true,
      headerClassName: "table-header-logo",
      flex: 3,
    },
    {
      field: "add",
      headerName: "",
      hide: !adding,
      headerClassName: "table-header-logo",
      flex: 2,
      renderCell: (params) =>
        params.row.id === "new" ? (
          <>
            <IconButton onClick={(e) => handleAddTechnician(e, params)}>
              <Done color="success" />
            </IconButton>
            <IconButton onClick={(e) => handleCancelAdding(e)}>
              <Close color="error" />
            </IconButton>
          </>
        ) : null,
    },
    {
      field: "created",
      headerName: t("Created"),
      hide: adding,
      editable: false,
      headerClassName: "table-header-logo",
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const dateFormatted = new Date(params.value.seconds * 1000).toLocaleDateString(
          "de-DE",
          dateTimeOptions
        );
        return dateFormatted;
      },
    },
    {
      field: "delete",
      headerName: t("Delete"),
      headerClassName: "table-header",
      flex: 1,
      hide: !showDelete,
      renderCell: (params) => (
        <IconButton onClick={(e) => handleDeleteEmployee(e, params)}>
          <Delete color="error" />
        </IconButton>
      ),
    },
  ];

  const handleCellEdit = (data) => {
    if (!adding) {
      console.log("data :>> ", data);
      console.log(data.value);
      const employee = employees.find((t) => t.id === data.id);
      if (employee[data.field] !== data.value) {
        dispatch(updateEmployee(data.id, data.field, data.value));
      }
    } else {
      if (data.value === "") {
        return;
      } else {
        const employee = { name: data.value };

        dispatch(addEmployee(employee));
        setAdding(false);
      }
    }
  };

  const [rows, setRows] = useState(employees.map((t, i) => t));

  useEffect(() => {
    setRows(employees.map((t, i) => t));

    return () => {};
  }, [employees]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1, background: "white" }}>
        <DataGrid
          loading={employeesLoading}
          rows={rows}
          columns={columns}
          onCellEditCommit={(data) => handleCellEdit(data)}
          keyboard
          headerHeight={65}
          pageSize={100}
          disableSelectionOnClick
          components={{
            Toolbar: () =>
              CustomToolbar(props, showDelete, setShowDelete, rows, setRows, adding, setAdding),
            LoadingOverlay: LinearProgress,
          }}
          sx={{
            minHeight: "86vh",
            padding: "15px",
            fontSize: "1.1rem",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .table-header": {
              marginTop: "10px",
            },
            "& .dtype-cell": {
              color: "grey",
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "300px !important",
            },
            "& .MuiDataGrid-cell": {
              maxHeight: "300px !important",
              padding: "10px",
              fontSize: "1.4rem",
            },
            "& .MuiDataGrid-editInputCell": {
              fontSize: "1.4rem",
            },
            // "& .MuiDataGrid-columnHeaderTitle": {
            //   fontWeight: "bold",

            // },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
        />
      </div>
    </div>
  );
}
