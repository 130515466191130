import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CheckPath = ({ setAdminAuthenticated }) => {
  const location = useLocation();
  useEffect(() => {
    // check if path includes settings
    if (location.pathname.includes("settings") || location.pathname.includes("employees")) {
      console.log("settings || employees");
    } else {
      setAdminAuthenticated(false);
    }
  }, [location.pathname, setAdminAuthenticated]);
  return <div style={{ display: "none" }}>CheckPath</div>;
};

export default CheckPath;
