import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import ListSubheader from "@mui/material/ListSubheader";
import SettingsIcon from "@mui/icons-material/Settings";

import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import {
  AccountBox,
  Badge,
  BookmarkBorderOutlined,
  Category,
  Engineering,
  ExpandLess,
  ExpandMore,
  People,
  Receipt,
  Smartphone,
  Store,
  TurnedIn,
} from "@mui/icons-material";
import DevicesIcon from "@mui/icons-material/Devices";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const linkStyle = {
  textDecoration: "none",
  color: "inherit",
  fontSize: "1.1rem",
};

export default function AdminList() {
  const { t } = useTranslation();
  const location = useLocation();
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openEmployees, setOpenEmployees] = React.useState(false);

  const handleSettingClick = () => {
    setOpenSettings(!openSettings);
  };

  const handleEmployeeClick = () => {
    setOpenEmployees(!openEmployees);
  };
  return (
    <React.Fragment>
      <Typography variant="overline" color="primary">
        {t("Admin")}
      </Typography>
      <NavLink to="/admin/device-types" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/device-types"}>
          <ListItemIcon>
            <DevicesIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Device Types")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/admin/brands" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/admin/brands"}>
          <ListItemIcon>
            <BookmarkBorderOutlined fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Brands")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/admin/devices" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/admin/devices"}>
          <ListItemIcon>
            <Smartphone fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Devices")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/admin/items" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/admin/items"}>
          <ListItemIcon>
            <Category fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Items")} />
        </ListItemButton>
      </NavLink>
      {/* <NavLink to="/admin/technicians" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/admin/technicians"}>
          <ListItemIcon>
            <Engineering fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Technicians")} />
        </ListItemButton>
      </NavLink> */}
      {/* <NavLink to="/admin/employees" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/admin/employees"}>
          <ListItemIcon>
            <People fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Employees")} />
        </ListItemButton>
      </NavLink> */}
      <NavLink to="/customers" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/customers"}>
          <ListItemIcon>
            <People fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Customers")} />
        </ListItemButton>
      </NavLink>

      {/* Employees */}
      <ListItemButton
        selected={location.pathname === "/settings"}
        onClick={handleEmployeeClick}
        style={linkStyle}
      >
        <ListItemIcon>
          <AccountBox fontSize="large" />
        </ListItemIcon>
        <ListItemText disableTypography={true} primary={t("Employees")} />
        {openEmployees ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openEmployees} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/admin/employees" style={linkStyle}>
            <ListItemButton sx={{ pl: 6 }} selected={location.pathname === "/admin/employees"}>
              <ListItemIcon>
                <Badge />
              </ListItemIcon>
              <ListItemText primary={t("Contractor")} />
            </ListItemButton>
          </NavLink>
          <NavLink to="/admin/technicians" style={linkStyle}>
            <ListItemButton sx={{ pl: 6 }} selected={location.pathname === "/admin/technicians"}>
              <ListItemIcon>
                <Engineering />
              </ListItemIcon>
              <ListItemText primary={t("Technicians")} />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>
      {/* SETTINGS */}
      <ListItemButton
        selected={location.pathname === "/settings"}
        onClick={handleSettingClick}
        style={linkStyle}
      >
        <ListItemIcon>
          <SettingsIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText disableTypography={true} primary={t("Settings")} />
        {openSettings ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/settings/company" style={linkStyle}>
            <ListItemButton sx={{ pl: 6 }} selected={location.pathname === "/settings/company"}>
              <ListItemIcon>
                <Store />
              </ListItemIcon>
              <ListItemText primary={t("Company")} />
            </ListItemButton>
          </NavLink>
          <NavLink to="/settings/invoice" style={linkStyle}>
            <ListItemButton sx={{ pl: 6 }} selected={location.pathname === "/settings/invoice"}>
              <ListItemIcon>
                <Receipt />
              </ListItemIcon>
              <ListItemText primary={t("Invoice")} />
            </ListItemButton>
          </NavLink>
          <NavLink to="/settings/labels" style={linkStyle}>
            <ListItemButton sx={{ pl: 6 }} selected={location.pathname === "/settings/labels"}>
              <ListItemIcon>
                <TurnedIn />
              </ListItemIcon>
              <ListItemText primary={t("Labels")} />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>
    </React.Fragment>
  );
}
