import * as React from 'react';

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { CircularProgress, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import SearchInput from '../Utils/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import { setDeviceModel } from '../../../Redux/Actions';
import DeviceActions from '../../../pages/Admin/Devices/DeviceActions';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const chipStyle = {
  fontSize: '1.2rem',
  height: '45px',
  paddingLeft: '15px',
  paddingRight: '15px',
  color: 'white',
  border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}`,
};

function Model(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currDevice = useSelector((state) => state.job.currentJob.device);
  const devices = useSelector((state) => state.job.currentDevices);
  const brandsDB = useSelector((state) => state.job.brands);
  const currDeviceType = useSelector((state) => state.job.currDeviceType);
  const [devicesFullName, setDevicesFullName] = React.useState(
    devices.map((d) => ({
      fullName: brandsDB.filter((b) => b.id === d.brandId)[0].name + ' ' + d.model,
      id: d.id,
      brandId: brandsDB.filter((b) => b.id === d.brandId)[0].id,
    }))
  );
  const devicesLoading = useSelector((state) => state.job.devicesLoading);

  console.log('brandsDB :>> ', brandsDB);
  const filteredBrands = brandsDB.filter((b) => b.deviceTypes.find((d) => d === currDeviceType.id));
  const [filteredDevices, setFilteredDevices] = React.useState(devicesFullName);
  const [brand, setBrand] = React.useState('All');
  const [newDevice, setNewDevice] = React.useState('');

  const [viewAddDevice, setViewAddDevice] = React.useState(false);

  console.log('filteredDevices', filteredDevices);

  const handleBrandFilter = (selectedBrand) => {
    if (selectedBrand === 'All') {
      setBrand('All');
    } else {
      setBrand(selectedBrand);
    }
  };

  React.useEffect(() => {
    if (brand === 'All') {
      const allDevices = devices.map((d) => ({
        fullName: brandsDB.filter((b) => b.id === d.brandId)[0].name + ' ' + d.model,
        id: d.id,
        brandId: brandsDB.filter((b) => b.id === d.brandId)[0].id,
      }));
      setFilteredDevices(allDevices);
      setDevicesFullName(allDevices);
    } else {
      const filtered = devices.filter((d) => d.brandId === brand.id);
      console.log('filtered :>> ', filtered);
      const filteredFullName = filtered.map((d) => ({
        fullName: brandsDB.filter((b) => b.id === d.brandId)[0].name + ' ' + d.model,
        id: d.id,
        brandId: brandsDB.filter((b) => b.id === d.brandId)[0].id,
      }));
      setDevicesFullName(filteredFullName);
      setFilteredDevices(filteredFullName);
    }

    return () => {};
  }, [brand, devices]);

  return (
    <div>
      {devicesLoading ? (
        <CircularProgress />
      ) : viewAddDevice ? (
        <DeviceActions
          from="job wizard"
          setViewAddDevice={setViewAddDevice}
          setNewDevice={setNewDevice}
          modelName={newDevice}
        />
      ) : (
        <div>
          <SearchInput
            devicesFullName={devicesFullName}
            setFilteredDevices={setFilteredDevices}
            setNewDevice={setNewDevice}
            brand={brand}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            {filteredBrands.length > 1 ? (
              <>
                <Chip
                  label={'Alle'}
                  key={'x'}
                  clickable
                  sx={chipStyle}
                  onClick={() => handleBrandFilter('All')}
                  color={'All' === brand ? 'primary' : 'default'}
                />
                {filteredBrands.map((b, i) => (
                  <Chip
                    label={b.name}
                    key={i}
                    clickable
                    sx={chipStyle}
                    onClick={() => handleBrandFilter(b)}
                    color={b.name === brand.name ? 'primary' : 'default'}
                  />
                ))}
              </>
            ) : null}
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className={'Wizard-btn-container'}
          >
            {filteredDevices.length > 0 ? (
              filteredDevices.map((d, i) => (
                <motion.div layout="position" className={'Wizard-btn-box'} key={i}>
                  <div
                    className={'Wizard-btn-big'}
                    onClick={() => {
                      props.setActiceStep(3);
                      dispatch(setDeviceModel(d));
                    }}
                    style={
                      d.id === currDevice.id
                        ? {
                            border: `5px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}`,
                            boxShadow: '0px 0px 20px 5px #00000086',
                          }
                        : null
                    }
                  >
                    {brandsDB.filter((b) => b.id === d.brandId)[0].logo === '' ? (
                      <div></div>
                    ) : (
                      <img
                        src={brandsDB.filter((b) => b.id === d.brandId)[0].logo}
                        alt="brand-logo"
                        width={145}
                        style={{ borderRadius: 'inherit' }}
                      />
                    )}
                  </div>
                  <Typography variant="h5">{d.fullName}</Typography>
                </motion.div>
              ))
            ) : (
              <div className={'Wizard-btn-box'}>
                <div className={'Wizard-btn-big'} onClick={() => setViewAddDevice(true)}>
                  <PhoneIphoneIcon sx={{ fontSize: '85px', color: '#7c7c7c' }} />
                </div>
                <Typography variant="h5">
                  {t('Add')} " {newDevice} "
                </Typography>
              </div>
            )}
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default Model;
