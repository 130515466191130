import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addEmployee, setJobEmployee } from "../../../Redux/Actions";

function EmployeeDialog({ openDialog, handleClosedDialog }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [employee, setEmployee] = useState("");
  const [adminPin, setAdminPin] = useState("");

  //   const handleValidatePin = () => {
  //     setLoading(true);
  //     // convert pin to number
  //     const numPin = Number(pin);
  //     // get doc from firebase from admins collection where pin = pin
  //     const q = query(
  //       collection(fireStore, process.env.REACT_APP_SUBCOLLECTION_PATH + 'admins'),
  //       where('pin', '==', numPin)
  //     );
  //     getDocs(q).then((docSnap) => {
  //       if (docSnap.docs.length > 0) {
  //         setAdminAuthenticated(true);
  //         setError(false);
  //         setLoading(false);
  //         setOpen(false);
  //       } else {
  //         setLoading(false);
  //         setError(true);
  //         setAdminAuthenticated(false);
  //       }
  //     });
  //   };

  const handleSaveEmployee = () => {
    const obj = {
      name: employee,
    };
    dispatch(addEmployee(obj, "add"));
    setEmployee("");
    handleClosedDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClosedDialog}
      aria-labelledby="date-picker-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="date-picker-dialog-title"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6" component="div">
          {t("Add Employee")}
        </Typography>
        <IconButton onClick={handleClosedDialog}>
          <Close />
        </IconButton>
      </DialogTitle>
      {/* style={{ minHeight: "60vh" }} */}
      <DialogContent sx={{ p: 4, pt: 0 }}>
        {/*  style={{
            display: "flex",
            flexDirection: "column",
            gap:"5px"
        }} */}
        <Box>
          <TextField
            fullWidth
            value={employee}
            label="Name"
            type="text"
            variant="filled"
            onChange={(e) => setEmployee(e.target.value)}
          />
          {/* <TextField
            value={employee}
            label="Admin PIN"
            type="text"
            variant="filled"
            onChange={(e) => setAdminPin(e.target.value)}
          /> */}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button variant="contained" color="primary" fullWidth onClick={handleSaveEmployee}>
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeDialog;
