import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { setDeviceType } from "../../../Redux/Actions";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TabletIcon from "@mui/icons-material/Tablet";
import ComputerIcon from "@mui/icons-material/Computer";
import { Typography } from "@mui/material";
import { DesktopMac, DesktopWindows, DevicesOther, LaptopMac } from "@mui/icons-material";

function DeviceType(props) {
  const deviceTypes = useSelector((state) => state.job.deviceTypes);
  const currDeviceType = useSelector((state) => state.job.currDeviceType);

  const dispatch = useDispatch();
  const handleTypeSelection = (dType) => {
    props.setActiceStep(2);
    dispatch(setDeviceType(dType));
  };

  return (
    <div className={"Wizard-btn-container"}>
      {deviceTypes.map((d, i) => (
        <div className={"Wizard-btn-box"} key={i} onClick={() => handleTypeSelection(d)}>
          <div
            className={"Wizard-btn-big"}
            style={
              d.id === currDeviceType.id
                ? {
                    border: "2px solid rgb(255, 255, 255)",
                    boxShadow: "0px 0px 20px 5px #00000086",
                    backgroundColor: process.env.REACT_APP_PRIMARY_CLR_MAIN,
                  }
                : { backgroundColor: process.env.REACT_APP_PRIMARY_CLR_MAIN }
            }
          >
            {i === 0 && <PhoneIphoneIcon sx={{ fontSize: "85px" }} />}
            {i === 1 && <TabletIcon sx={{ fontSize: "85px" }} />}
            {i === 2 && <DesktopMac sx={{ fontSize: "85px" }} />}
            {i === 3 && <LaptopMac sx={{ fontSize: "85px" }} />}
            {i === 4 && <ComputerIcon sx={{ fontSize: "85px" }} />}
            {i === 5 && <DesktopWindows sx={{ fontSize: "85px" }} />}
            {i === 6 && <DevicesOther sx={{ fontSize: "85px" }} />}
          </div>
          <Typography variant="h5">{d.name}</Typography>
        </div>
      ))}
    </div>
  );
}

export default DeviceType;
