import { Done, Schedule, Close, Add } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addTechnician, updateJobStatus, updateJobTechnician } from "../../../Redux/Actions";

const dateTimeOptions = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZone: "Europe/Berlin",
};

function JobProperties({ currentJob }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const jobsLoading = useSelector((state) => state.job.jobsLoading);
  const technicians = useSelector((state) => state.job.technicians);

  const [newTechnician, setNewTechnician] = React.useState("");
  const [addingNewTechnician, setAddingNewTechnician] = React.useState(false);

  const [selecting, setSelecting] = React.useState(false);

  const handleSetTechnician = (event) => {
    if (event.target.value === "new") {
      setAddingNewTechnician(true);
    } else {
      dispatch(updateJobTechnician(currentJob.id, event.target.value));
      setSelecting(false);
    }
    // setAge(event.target.value);
  };

  const handleUpdateStatus = (status) => {
    if (status !== currentJob.status) {
      dispatch(updateJobStatus(currentJob.id, status));
      setSelecting(true);
    }
  };

  const handleAddNewTechnician = () => {
    const newTech = { name: newTechnician };
    dispatch(addTechnician(newTech));
    dispatch(updateJobTechnician(currentJob.id, newTechnician));
    setSelecting(false);
    setAddingNewTechnician(false);
    setNewTechnician("");
  };

  return (
    <div>
      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Job")}#
      </Typography>
      <Typography variant="subtitle1">{currentJob.jobNumber.toString()}</Typography>
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />
      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Services")}
      </Typography>
      {currentJob.jobServices.map((r, i) => (
        <div key={i}>
          <Typography variant="subtitle1">{r.name}</Typography>
        </div>
      ))}
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />
      <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
        <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
          {t("Status")}
        </Typography>
        {jobsLoading ? (
          <CircularProgress />
        ) : (
          <div>
            {currentJob.status === "estimation" ? (
              <div>
                <Typography variant="subtitle1">{t("Estimation")}</Typography>
                <Button variant="contained" onClick={() => handleUpdateStatus("pending")}>
                  {t("Book repair")}
                </Button>
              </div>
            ) : (
              <>
                <ButtonGroup size="small">
                  <Button
                    variant={currentJob.status === "pending" ? "outlined" : "contained"}
                    color={currentJob.status === "pending" ? "warning" : "inherit"}
                    endIcon={currentJob.status === "pending" ? <Schedule /> : null}
                    onClick={() => handleUpdateStatus("pending")}
                  >
                    {t("Pending")}
                  </Button>
                  <Button
                    variant={currentJob.status !== "pending" ? "outlined" : "contained"}
                    color={currentJob.status === "pending" ? "inherit" : "success"}
                    endIcon={currentJob.status === "pending" ? null : <Done />}
                    onClick={() => handleUpdateStatus("completed")}
                  >
                    {t("Completed")}
                  </Button>
                </ButtonGroup>
                {currentJob.status === "completed" ? (
                  addingNewTechnician ? (
                    <TextField
                      value={newTechnician}
                      sx={{ marginTop: "20px" }}
                      autoFocus
                      onChange={(e) => setNewTechnician(e.target.value)}
                      fullWidth
                      label={t("New Technician")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={newTechnician === ""}
                              onClick={() => handleAddNewTechnician()}
                            >
                              <Done
                                color={newTechnician === "" ? "unset" : "success"}
                                disabled={newTechnician === ""}
                              />
                            </IconButton>
                            <IconButton onClick={() => setAddingNewTechnician(false)}>
                              <Close color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <FormControl fullWidth sx={{ marginTop: "20px" }}>
                      <InputLabel id="demo-simple-select-label">{t("Technician")}</InputLabel>
                      <Select
                        label={t("Technician")}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultOpen={selecting}
                        value={currentJob.technician}
                        onChange={handleSetTechnician}
                      >
                        {technicians.map((t, i) => (
                          <MenuItem key={i} value={t.name}>
                            {t.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={"new"}>
                          <Button fullWidth startIcon={<Add />} size="small">
                            {t("Add New")}
                          </Button>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )
                ) : null}
              </>
            )}
          </div>
        )}
      </div>

      <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Return Date")}
      </Typography>
      <Typography variant="subtitle1">
        {new Date(currentJob.returnDate.seconds * 1000).toLocaleDateString(
          "de-DE",
          dateTimeOptions
        )}
      </Typography>
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />
      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Notes")}
      </Typography>

      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />

      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Customer")}
      </Typography>
      <Typography variant="subtitle1">{currentJob.customer.name}</Typography>
      <Typography variant="subtitle1">{currentJob.customer.phone}</Typography>
      {currentJob?.employee && (
        <>
          <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />
          <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
            {t("Contractor")}
          </Typography>
          <Typography variant="subtitle1">{currentJob?.employee?.name}</Typography>
        </>
      )}

      {/*    <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />

      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        Booked By
      </Typography>
      <Typography variant="subtitle1">{currentJob.employee.name}</Typography> */}
    </div>
  );
}

export default JobProperties;
