import { Add, Person } from "@mui/icons-material";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setJobEmployee } from "../../../Redux/Actions";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmployeeDialog from "./EmployeeDialog";

function Employee(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.job.employees);
  const currJobEmployee = useSelector((state) => state.job.currentJob?.employee);

  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);

  const handleTypeSelection = (employee) => {
    props.setActiceStep(1);
    dispatch(setJobEmployee(employee));
  };

  const handleAddEmployeeDialog = () => {
    setOpenEmployeeDialog(true);
  };

  const handleClosedDialog = () => {
    setOpenEmployeeDialog(false);
  };
  return (
    <>
      <Typography
        variant="h4"
        sx={{
          width: "100%",
        }}
      >
        {t("Contractor")}
      </Typography>
      <div className={"Wizard-btn-container"}>
        <div className={"Wizard-btn-box"} onClick={handleAddEmployeeDialog}>
          <div
            className={"Wizard-btn-big"}
            style={{
              backgroundColor: "transparent",
              //   backgroundColor: process.env.REACT_APP_PRIMARY_CLR_MAIN,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              fontSize: "18px",
            }}
          >
            <Add
              sx={{
                fontSize: "96px",
              }}
            />
            {t("Contractor")}
          </div>
        </div>
        {employees?.map((d, i) => (
          <div className={"Wizard-btn-box"} key={i} onClick={() => handleTypeSelection(d)}>
            <div
              className={"Wizard-btn-big"}
              style={
                d.id === currJobEmployee?.id
                  ? {
                      border: "2px solid rgb(255, 255, 255)",
                      boxShadow: "0px 0px 20px 5px #00000086",
                      backgroundColor: process.env.REACT_APP_PRIMARY_CLR_MAIN,
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      fontSize: "18px",
                    }
                  : {
                      backgroundColor: process.env.REACT_APP_PRIMARY_CLR_MAIN,
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      fontSize: "18px",
                    }
              }
            >
              <Person
                sx={{
                  fontSize: "96px",
                }}
              />
              {d.name}
            </div>
          </div>
        ))}
      </div>

      {openEmployeeDialog && (
        <EmployeeDialog openDialog={openEmployeeDialog} handleClosedDialog={handleClosedDialog} />
      )}
    </>
  );
}

export default Employee;
